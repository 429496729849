<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link to="/module/branch-course" class="btn btn-primary align-self-center ms-3">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.branchCourse.branch.title") }}
        </router-link>
    </div>
    <custom-table
        :title="sprintf($t('pages.module.branchCourse.titlePattern'), [branch.title ?? ''])"
        :subTitle="$t('pages.module.branchCourse.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.branchCourse.reservations')" placement="top" v-if="record.reservations_count > 0">
                    <router-link :to="'/module/branch-course/reservation?branchCourseID=' + record.id" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                        </span>
                    </router-link>
                </el-tooltip>

                <a v-on:click="fetchBranchCourse(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_branch_course" ref="addBranchCourseModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="branchCourseForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.image') }}</label>
                                <el-form-item prop="image_id">
                                    <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                        <i class="bi bi-plus" />
                                    </el-upload>
                                    <el-dialog v-model="image.dialogVisible" width="30%">
                                        <img style="width: 100%" :src="image.dialogImageUrl" alt="" />
                                    </el-dialog>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.description') }}</label>
                                <el-form-item prop="description">
                                    <el-input v-model="form.data.description" type="textarea" rows="5"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.teacher') }}</label>
                                <el-form-item prop="teacher_id">
                                    <el-select v-model="form.data.teacher_id" class="w-100" :placeholder="$t('common.chooseSelect')" clearable>
                                        <el-option v-for="(teacher, teacherIndex) in teachers" :key="teacherIndex" :value="teacher.id" :label="teacher.firstname + ' ' + teacher.lastname"></el-option>
                                    </el-select>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.startDate') }}</label>
                                <el-form-item prop="start_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker v-model="form.data.start_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledStartDate"></el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.endDate') }}</label>
                                <el-form-item prop="end_date" :rules="$validation.getMessage(['required'])">
                                    <el-date-picker v-model="form.data.end_date" type="date" :placeholder="$t('common.chooseDate')" valueFormat="YYYY-MM-DD" :disabledDate="disabledEndDate"></el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.previewStartDate') }}</label>
                                <el-form-item prop="preview_start_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.preview_start_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledPreviewStartDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>
                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.previewEndDate') }}</label>
                                <el-form-item prop="preview_end_date">
                                    <el-date-picker
                                        class="rangeDateTimePicker"
                                        v-model="form.data.preview_end_date"
                                        type="datetime"
                                        popper-class="rangeDateTimePickerPopper"
                                        :placeholder="$t('common.chooseDateAndTime')"
                                        valueFormat="YYYY-MM-DD HH:mm:ss"
                                        :disabledDate="disabledPreviewEndDate"
                                    >
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.lessonDay') }}</label>
                                <el-form-item prop="lesson_day">
                                    <el-input v-model="form.data.lesson_day" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.lessonHour') }}</label>
                                <el-form-item prop="lesson_hour">
                                    <el-input v-model="form.data.lesson_hour" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.quota') }}</label>
                                <el-form-item prop="quota" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.quota" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.fakeQuota') }}</label>
                                <el-form-item prop="fakeQuota">
                                    <el-input-number v-model="form.data.fake_quota" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.branchCourse.cols.status') }}</label>
                                <el-form-item prop="status">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{  $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "_id",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("pages.module.branchCourse.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.branchCourse.cols.title"),
                    key: "title"
                },
                {
                    name: this.$t("pages.module.branchCourse.cols.quota"),
                    key: "quota"
                },
                {
                    name: this.$t("pages.module.branchCourse.cols.reservationCount"),
                    key: "reservations_count"
                },
                {
                    name: this.$t("pages.module.branchCourse.cols.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("pages.module.branchCourse.cols.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
            branch: {}
        }
    },
    computed: {
        table() {
            return this.$store.state.module.branchCourse.table;
        },
        teachers() {
            return this.$store.state.module.teacher.table.data;
        },
        branchID(){
            return this.$route.params.id;
        }
    },

    created() {
        if (this.branchID && !(this.branchID > 0)) {
            this.$router.push({
                path: "/module/branch-course"
            });
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.branchCourse.title"), [this.$t("menu.moduleManagement"),  this.$t("pages.module.branchCourse.managementTitle")]);
        if (this.branchID && this.branchID > 0) {
            this.$store.dispatch('module/branchCourse/get', {
                page: {
                    current: this.$route.query.page
                },
                filterData: {
                    branch_id: this.branchID,
                    sort: 'id:desc'
                }
            });

            this.$store.dispatch('module/teacher/get', {
                page: {pageSize: 9999}
            });

            this.loadBranch();
        }
    },
    methods:{
        loadBranch(){
            this.axios.get(this.endpoints['module_branch_course_branch'] + '/' + this.branchID).then((response) => {
                this.branch = response.data.data;
            });
        },
        newBranchCourse(){
            this.form.updateStatus = false;
            this.resetImageData();
            this.form.data = {
                branch_id: this.branchID,
                quota: 1,
                fake_quota: 1,
                sort: 1,
                active: true
            };
            this.form.title = this.$t("pages.module.branchCourse.newBranchCourse");
            this.showModal(this.$refs.addBranchCourseModal);
        },
        fetchBranchCourse(record) {
            this.resetImageData();
            this.form.updateStatus = true;
            this.form.title =  this.$t("pages.module.branchCourse.editBranchCourse")
            this.axios.get(this.endpoints['module_branch_course']+ '/' + record.id).then(response => {
                let data = response.data.data;

                data.preview_start_date = data.preview_start_date ? this.$moment(data.preview_start_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.preview_end_date = data.preview_end_date ? this.$moment(data.preview_end_date).format('YYYY-MM-DD HH:mm:ss') : undefined;

                this.form.data = data;

                if(data.image){
                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }

                this.showModal(this.$refs.addBranchCourseModal);
            });
        },
        onSubmit(){
            this.$refs.branchCourseForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    if(this.image.uploadList.length > 0){
                        this.submitFormWithUpload();
                    } else {
                        if(!(this.image.fileList.length > 0)){
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload(){
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm(){
            if(this.form.data.id) {
                this.axios.put(this.endpoints['module_branch_course'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.addBranchCourseModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }else {
                this.axios.post(this.endpoints['module_branch_course'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.addBranchCourseModal);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        deleteRecord(id){
            this.$store.dispatch("module/branchCourse/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newBranchCourse();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable(){
            this.$store.dispatch("module/branchCourse/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/branchCourse/get", {
                page: pagination,
                filterData: Object.assign({
                    branch_id: this.branchID,
                    sort: 'id:desc'
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        resetImageData(){
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList){
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        },
        disabledStartDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.end_date ? this.$moment(this.form.data.end_date) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledEndDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.start_date ? this.$moment(this.form.data.start_date) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
        disabledPreviewStartDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.preview_end_date ? this.$moment(this.form.data.preview_end_date) : undefined;

            return endDate == undefined ? false : startDate.valueOf() > endDate.valueOf()
        },
        disabledPreviewEndDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.preview_start_date ? this.$moment(this.form.data.preview_start_date) : undefined;

            return startDate == undefined ? false : startDate.valueOf() > endDate.valueOf();
        },
    }
}
</script>

<style></style>